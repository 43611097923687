import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { fetchApiPost } from "./utils/FetchApi";
import { ApiName } from "./Constant/ApiName";
import LandingPageScreen from "./screens/LandingPageScreen";
import OtpPageScreen from "./screens/OtpPageScreen";
import TagManager from "react-gtm-module";
import { gtmId, password, username } from "./utils/config";

const LandingRoute = () => {
  // State
  const [jwtToken, setJwtToken] = useState<string | null>(null);

  useEffect(() => {
    generateJwtToken();
    const tagManagerArgs = {
      gtmId: gtmId,
    };
    TagManager.initialize(tagManagerArgs);
  }, []);

  // function
  const generateJwtToken = async () => {
    try {
      let data: any = {
        username: username,
        password: password,
      };
      const response = await fetchApiPost(null, ApiName.generateGwtToken, data);

      if (response.status === 0) {
        setJwtToken(response.token);
      }
    } catch (error) {
      console.log("Jwt token" + error);
    }
  };

  return (
    <Routes>
      <Route
        path="/landing"
        element={<LandingPageScreen jwtToken={jwtToken} />}
      />
      <Route path="/otp" element={<OtpPageScreen jwtToken={jwtToken} />} />
    </Routes>
  );
};

export default LandingRoute;
