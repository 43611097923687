import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { encode as base64_encode } from "base-64";
import { ApiName } from "../Constant/ApiName";
import { fetchApiGet, fetchApiPost } from "../utils/FetchApi";
import tagManagerEvents from "../utils/GoogleTagManager";
import LandingPage from "../pages/LandingPage";

type Props = {
  jwtToken: string | null;
};

let heMsisdn: string = "";
const LandingPageScreen = (props: Props) => {
  const navigate = useNavigate();
  // state
  const [msisdn, setMsisdn] = useState<string>("");
  const [utm, setUtm] = useState<string | null>("");
  const [subPackage, setSubPackage] = useState<string>("");
  const [error, setError] = useState("");
  const [showErro, setShowErro] = useState(false);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    // getUtm();
    if (props.jwtToken) {
      getUrlUtm();
    }
  }, [props.jwtToken]);

  // function
  const getUrlUtm = () => {
    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);
    const utmSource: string | null = searchParams.get("utm_source");
    setUtm(utmSource);
    getFlow(utmSource);
  };

  const getFlow = async (_utm: string | null) => {
    try {
      let endPoint = `${ApiName.getFLow}?utm_source=${_utm}`;
      let response = await fetchApiGet(props.jwtToken, endPoint);

      if (response.status === 0) {
        if (
          response.result.isSpecialFlow &&
          response.result.msisdn.length === 10 &&
          response.result.otp.length === 4
        ) {
          const data = {
            msisdn: response.result.msisdn,
            otp: response.result.otp,
            isSpecialFlow: response.result.isSpecialFlow,
            isFastFlow: response.result.fastFlow,
            specialFlowTime: response.result.milliseconds,
            subPackage: response.result.subPackage,
            utm: _utm,
          };
          setMsisdn(response.result.msisdn);
          navigate("/otp", { state: data });
        } else if (response.result.msisdn.length === 10) {
          tagManagerEvents("DATA_USER", _utm);
          setMsisdn(response.result.msisdn);
          heMsisdn = response.result.msisdn;
          if (response.result.subPackage) {
            setSubPackage(response.result.subPackage);
          }
          if (response.result.signIn) {
            loginUser(response.result.msisdn);
          }
        } else {
          tagManagerEvents("WIFI_USER", utm);
        }
      }
    } catch (error) {
      console.log("get flow error" + error);
    }
  };

  const loginUser = async (_msisdn: string) => {
    try {
      let data = {
        msisdn: _msisdn,
        otp: "",
        is_he_user: true,
      };

      let response = await fetchApiPost(props.jwtToken, ApiName.login, data);

      if (response.status === 0) {
        let url = window.location.origin;
        let encodedMsisdn = base64_encode(_msisdn);
        let _package = "";
        if (subPackage.toLowerCase() === "pre") {
          _package = "prepaid";
        } else {
          _package = "postpaid";
        }
        let encodedPackage = base64_encode(_package);
        url = `${url}/home?user=${encodedMsisdn}&package=${encodedPackage}`;
        if (url.includes("http:")) {
          url = url.replace("http", "https");
        }
        window.location.replace(url);
      }
    } catch (error) {}
  };

  const handleUserMsisdnInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    let userInputNumber = e.target.value;
    if (
      !userInputNumber ||
      (userInputNumber.length <= 10 && userInputNumber.match(/^3\d{0,9}$/))
    ) {
      setShowErro(false);
      setMsisdn(e.target.value);
    }
  };
  const onContinuePressed = () => {
    if (msisdn?.length === 10) {
      setShowErro(false);
      if (msisdn === heMsisdn && subPackage) {
        sendOtp(subPackage);
      } else {
        checkOperator();
      }
    } else {
      setLoader(false);
      setShowErro(true);
      setError("Please enter 10 digit mobile number");
    }
  };

  // Api Calls

  const checkOperator = async () => {
    try {
      setLoader(true);
      let endPoint = ApiName.checkOperator + "?msisdn=" + msisdn;
      let response = await fetchApiGet(props.jwtToken, endPoint);
      if (response.status === 0) {
        if (response.network.toLowerCase() !== "other network") {
          sendOtp(response.package);
        } else {
          setShowErro(true);
          setLoader(false);
          setError(
            "Service is only available for Zong customers, kindly enter a working Zong number"
          );
        }
      } else {
        setShowErro(true);
        setLoader(false);
        setError("Something went wrong");
      }
    } catch (error) {
      setLoader(false);
      setShowErro(true);
      setError("Something went wrong");
      console.log("Check Operator" + error);
    }
  };
  const sendOtp = async (subPackage: string) => {
    try {
      let isExists: boolean = false;
      let data = {
        msisdn: msisdn,
        os: "web",
      };
      let endPoint = `${ApiName.signupweb}`;

      let response = await fetchApiPost(props.jwtToken, endPoint, data);
      if (response.status === 0) {
        tagManagerEvents("REQUEST_OTP", utm);
        if (response.exists) {
          if (response.package?.toLowerCase() === "subt") {
            isExists = false;
          } else {
            isExists = true;
          }
        } else {
          isExists = false;
        }
        let data = {
          msisdn: msisdn,
          otp: msisdn === heMsisdn ? response.otp : "",
          utm: utm,
          isExists: isExists,
          subPackage: subPackage,
        };
        setLoader(false);
        navigate("/otp", {
          state: data,
        });
      } else {
        setLoader(false);
        setShowErro(true);
        setError("Something went wrong");
      }
    } catch (error) {
      setLoader(false);
      setShowErro(true);
      setError("Something went wrong");
      console.log("signUpWeb" + error);
    }
  };

  return (
    <>
      <LandingPage
        msisdn={msisdn}
        handleUserMsisdnInput={handleUserMsisdnInput}
        onPressContinueBtn={onContinuePressed}
        error={error}
        loader={loader}
        showErro={showErro}
      />
    </>
  );
};

export default LandingPageScreen;
