import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { encode as base64_encode } from "base-64";
import OtpPage from "../pages/OtpPage";
import { ApiName } from "../Constant/ApiName";
import { fetchApiGet, fetchApiPost } from "../utils/FetchApi";
import tagManagerEvents from "../utils/GoogleTagManager";

type Props = {
  jwtToken: string | null;
};
let adNetwork = "";
const OtpPageScreen = (props: Props) => {
  const { state } = useLocation();
  const navigate = useNavigate();
  // state
  const [inputRef, setInputRef] = useState<React.RefObject<HTMLInputElement>[]>(
    [
      React.createRef<HTMLInputElement>(),
      React.createRef<HTMLInputElement>(),
      React.createRef<HTMLInputElement>(),
      React.createRef<HTMLInputElement>(),
    ]
  );
  const [otp, setOtp] = useState({
    one: "",
    two: "",
    three: "",
    four: "",
  });
  const [error, setError] = useState("");
  const [showErro, setShowErro] = useState(false);
  const [loader, setLoader] = useState(false);
  const [showResendButton, setShowResendButton] = useState(false);
  const [timer, setTimer] = useState(30); // Set initial timer value

  useEffect(() => {
    if (!state?.msisdn) {
      navigate("/landing");
    } else {
      checkUtms();
      if (state.isSpecialFlow) {
        handleSpecialFlow();
      } else if (state.otp) {
        let _otp = state.otp.split("");
        let tempOTP = { ...otp };
        tempOTP.one = _otp[0];
        tempOTP.two = _otp[1];
        tempOTP.three = _otp[2];
        tempOTP.four = _otp[3];
        setOtp(tempOTP);
      }
    }
  }, []);
  useEffect(() => {
    let countdown: NodeJS.Timeout;
    if (!showResendButton) {
      countdown = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer === 0) {
            setShowResendButton(true);
            clearInterval(countdown); // Stop the countdown
            return prevTimer;
          }
          return prevTimer - 1;
        });
      }, 1000);
    }

    return () => {
      if (countdown) {
        clearInterval(countdown); // Clear the countdown interval on unmount
      }
    };
  }, [showResendButton]);
  // function
  const handleSpecialFlow = () => {
    const _otp = state.otp.split("");
    setOtp({
      one: _otp[0],
      two: _otp[1],
      three: _otp[2],
      four: _otp[3],
    });
    if (state.specialFlowTime && state.specialFlowTime >= 0) {
      setTimeout(() => {
        const apiName = state.isFastFlow
          ? `${ApiName.ffSubscribe}`
          : ApiName.subscribe;
        subscribeUser(apiName, _otp[0] + _otp[1] + _otp[2] + _otp[3]);
      }, state.specialFlowTime);
    } else {
      const apiName = state.isFastFlow
        ? `${ApiName.ffSubscribe}`
        : ApiName.subscribe;
      subscribeUser(apiName, _otp[0] + _otp[1] + _otp[2] + _otp[3]);
    }
  };
  const handleOtpInput = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    setShowErro(false);
    const newValue = e.target.value;
    if (!isNaN(newValue as any) && newValue !== " ") {
      setOtp({
        ...otp,
        [e.target.name]: newValue,
      });
      if (e.target.value.length === 1) {
        const nextInput = inputRef[index + 1];
        if (nextInput?.current) {
          nextInput.current.focus();
        }
      }
    } else {
      setLoader(false);
      setShowErro(true);
      setError("Please enter a valid OTP");
    }
  };
  const handleBackspace = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ): void => {
    if (e.keyCode === 8) {
      setShowErro(false);
      const target = e.target as HTMLInputElement;
      if (target.value.length === 0 && index !== 0) {
        (inputRef[index - 1].current as HTMLInputElement).focus();
      }
    }
  };
  const onOtpSubmit = () => {
    let _otp: string = otp.one + otp.two + otp.three + otp.four;
    if (_otp.length === 4) {
      // handleVerifyOtp(_otp);
      if (state.isExists) {
        loginUser(_otp);
      } else {
        // subscribe
        subscribeUser(ApiName.subscribe, _otp);
      }
    } else {
      setLoader(false);
      setShowErro(true);
      setError("Enter a Valid OTP");
    }
  };
  // APi calls
  const checkUtms = async () => {
    try {
      let response = await fetchApiGet(props.jwtToken, ApiName.utm_list);

      if (response.status === 200) {
        response.response.map((e: any) => {
          if (state.utm === e.sf_utm) {
            adNetwork = e.ad_network;
          }
        });
      }
      // console.warn("adNEtwork", adNetwork);
    } catch (error) {
      console.log("check utm error", error);
    }
  };

  const subscribeUser = async (apiName: string, _otp: string) => {
    try {
      setLoader(true);
      // let response = await fetchApiGet(props.jwtToken, _ApiName);
      let data = {
        msisdn: state.msisdn,
        otp: _otp,
        subPackage: state.subPackage,
        utm_source: state.utm,
      };

      let response = await fetchApiPost(props.jwtToken, apiName, data);

      if (response.status === 0) {
        if (
          adNetwork.toLowerCase() === "tiktok" ||
          adNetwork.toLowerCase() === "tik tok"
        ) {
          tagManagerEvents("SUBSCRIBE", "econTikTok");
        } else if (adNetwork.toLowerCase() === "google") {
          tagManagerEvents("SUBSCRIBE", "econceptions");
        } else {
          tagManagerEvents("SUBSCRIBE", state.utm);
        }
        let url = window.location.origin;
        let encodedMsisdn = base64_encode(state.msisdn);
        let _package = "";
        if (state?.subPackage.toLowerCase() === "pre") {
          _package = "prepaid";
        } else {
          _package = "postpaid";
        }

        let encodedPackage = base64_encode(_package);

        url = `${url}/home?user=${encodedMsisdn}&package=${encodedPackage}`;
        if (url.includes("http:")) {
          url = url.replace("http", "https");
        }
        window.location.replace(url);
      } else {
        tagManagerEvents("SUBSCRIBE_FAIL", state.utm);
        setShowErro(true);
        setError("Unable to Subscribe");
        setLoader(false);
      }
    } catch (error) {
      setShowErro(true);
      setError("Something went wrong, Please try again.");
      setLoader(false);
    }
  };

  const loginUser = async (_otp: string) => {
    try {
      setLoader(true);
      // let response = await fetchApiGet(props.jwtToken, _ApiName);
      let data = {
        msisdn: state.msisdn,
        otp: _otp,
        is_he_user: false,
      };

      let response = await fetchApiPost(props.jwtToken, ApiName.login, data);

      if (response.status === 0) {
        let url = window.location.origin;
        let encodedMsisdn = base64_encode(state.msisdn);
        let _package = "";
        if (state?.subPackage.toLowerCase() === "pre") {
          _package = "prepaid";
        } else {
          _package = "postpaid";
        }
        let encodedPackage = base64_encode(_package);

        url = `${url}/home?user=${encodedMsisdn}&package=${encodedPackage}`;
        if (url.includes("http:")) {
          url = url.replace("http", "https");
        }
        window.location.replace(url);
      } else {
        tagManagerEvents("SUBSCRIBE_FAIL", state.utm);
        setShowErro(true);
        setError("Unable to Subscribe");
        setLoader(false);
      }
    } catch (error) {
      setShowErro(true);
      setError("Something went wrong, Please try again.");
      setLoader(false);
    }
  };

  const onResendOtp = async () => {
    try {
      setTimer(30); // Reset the timer value
      setShowResendButton(false); // Hide the resend button
      let data = {
        msisdn: state.msisdn,
        os: "web",
      };
      let endPoint = `${ApiName.signupweb}`;

      let response = await fetchApiPost(props.jwtToken, endPoint, data);
      if (response.status === 0) {
        setOtp({
          one: "",
          two: "",
          three: "",
          four: "",
        });
        if (inputRef[0]?.current) {
          inputRef[0].current.focus();
        }
        setShowErro(false);
      } else {
        setShowErro(true);
        setError("Unable to send OTP, Please try again");
        setLoader(false);
      }
    } catch (error) {
      console.log("signUpWeb" + error);
      setShowErro(true);
      setError("Something went wrong, Please try again.");
      setLoader(false);
    }
  };

  return (
    <>
      <OtpPage
        inputRef={inputRef}
        otp={otp}
        handleOtpInput={handleOtpInput}
        handleBackspace={handleBackspace}
        onOtpSubmit={onOtpSubmit}
        error={error}
        loader={loader}
        showErro={showErro}
        onResendOtp={onResendOtp}
        timer={timer}
        showResendButton={showResendButton}
      />
    </>
  );
};

export default OtpPageScreen;
